<template>
  <div>
    <div class="title-tabs">
      <b-row>
        <b-col md="6" class="text-left"> Image </b-col>
        <b-col md="6" class="text-right">
          <span v-if="id !== 0">
            Latest Update
            {{ $moment(form.updated_time).format("DD/MM/YYYY") }} ({{
              $moment(form.updated_time).format("HH:mm:ss")
            }})
          </span>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white p-3">
      <b-row>
        <b-col>
          <PreviewBox
            v-on:delete="onDeleteImage"
            :disabled="isDisable"
            :showPreviewType="0"
            :showPreview="showPreview"
            :isLoadingImage="isLoadingImage"
            :ratioType="1"
          />
        </b-col>
        <b-col>
          <UploadFile
            v-model="form.image_url"
            :value="form.image_url"
            textFloat="Cover Image"
            placeholder="Please Select a File To Upload."
            format="image"
            name="thumbnail"
            accept="image/jpeg, image/png"
            text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
            v-on:onFileChange="onImageChange"
            :fileName="form.image_url"
          />
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import UploadFile from "@/components/inputs/UploadFile";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  components: {
    UploadFile,
    PreviewBox,
  },
  props: {
    form: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
    id: {
      required: true,
      type: Number,
    },
  },
  data() {
    return {
      showPreview: this.form.image_url,
      isLoadingImage: false,
      isDisable: false,
    };
  },
  methods: {
    onDeleteImage() {
      this.form.image_url = "";
      this.showPreview = null;
    },
    onImageChange(img) {
      this.isLoadingImage = true;
      this.isDisable = true;
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        let img = new Image();

        img.src = reader.result;
        img.onload = () => {
          if (parseInt(img.height) !== parseInt(img.width)) {
            this.warningAlert("Please upload image with ratio 1:1 !");
            this.isLoadingImage = false;
            this.isDisable = false;
          } else {
            this.images = this.saveImagetoDb(reader.result);
            this.isLoadingImage = false;
            this.isDisable = false;
            this.form.image_url = this.images;
            this.showPreview = this.form.image_url;
          }
        };
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
        is_video: false,
        type: 0,
      };
      await this.$store.dispatch("uploadProductImage", form);
      var data = await this.$store.state.product.stateUploadProductImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-custom {
  background-color: var(--theme-secondary-color);
  color: #000;
  font-weight: 600;
  padding: 10px 0;
  margin: 0;
}
.positionCloseIcon {
  position: absolute;
  margin-left: -15px;
}
</style>
