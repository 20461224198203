<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <div class="bg-white p-2 p-lg-3">
        <b-row class="row-gap-md">
          <template v-for="(field, index) of form.fields">
            <b-col
              :key="'field-' + field.id + index"
              v-if="field.field_type_id == 1"
            >
              <InputText
                :textFloat="field.name"
                :placeholder="field.name"
                v-model="field.value"
                value=""
                type="text"
                class="mb-0"
            /></b-col>

            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id == 2"
            >
              <label for="">{{ field.name }} </label>

              <b-form-checkbox-group
                :id="'checkbox-' + field.id"
                :name="'checkbox-' + field.id"
                :options="field.field_choices"
                v-model="field.value"
                value-field="id"
                text-field="name"
                stacked
              ></b-form-checkbox-group>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id == 3"
            >
              <label for="">{{ field.name }} </label>
              <multiselect
                :close-on-select="false"
                :clear-on-select="false"
                :options="field.field_choices"
                v-model="field.value"
                :placeholder="field.name"
                track-by="id"
                label="name"
                multiple
              >
                <template v-slot:option-first>
                  <b-form-select-option :value="null"
                    >-- Select {{ field.name }} --</b-form-select-option
                  >
                </template>
              </multiselect>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id == 4"
            >
              <InputSelect
                :title="field.name"
                :name="field.name"
                v-model="field.value"
                v-bind:options="field.field_choices"
                valueField="id"
                textField="name"
                class="mb-0"
              >
                <!-- {{ undefined }} -->
                <template v-slot:option-first>
                  <b-form-select-option value="" disabled>
                    -- Select {{ field.name }} --
                  </b-form-select-option>
                </template>
              </InputSelect>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id === 6"
            >
              <InputSelect
                :title="field.name"
                :name="field.name"
                v-model="field.value"
                v-bind:options="field.branch_list || []"
                valueField="id"
                textField="name"
                class="mt-3"
              >
                <template v-slot:option-first>
                  <b-form-select-option value=""
                    >-- Select {{ field.name }} --</b-form-select-option
                  >
                </template>
              </InputSelect>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id === 5"
            >
              <b-row>
                <b-col>
                  <label class="label">{{ field.name }}</label>
                  <div class="input-container">
                    <datetime
                      type="date"
                      class="date-picker"
                      :input-style="styleDatetime"
                      v-model="field.value"
                      placeholder="DD/MM/YYYY"
                      format="dd/MM/yyyy"
                      value-zone="Asia/Bangkok"
                      :ref="`${field.id}-datetime`"
                    >
                    </datetime>
                    <div
                      :class="'icon-primary text-right'"
                      @click="$refs[`${field.id}-datetime`][0].isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="'pointer color-primary'"
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </b-col>
                <b-col class="mt-auto">
                  <div class="input-container">
                    <datetime
                      type="time"
                      class="date-picker"
                      :input-style="styleDatetime"
                      v-model="field.value"
                      placeholder="HH:MM"
                      format="HH:mm"
                      value-zone="Asia/Bangkok"
                      :ref="`${field.id}-time`"
                    >
                    </datetime>
                    <div
                      :class="'icon-primary text-right'"
                      @click="$refs[`${field.id}-time`][0].isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="'pointer color-primary'"
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id === 7"
            >
              <b-row>
                <b-col>
                  <label class="label">{{ field.name }}</label>
                  <div class="input-container">
                    <datetime
                      type="date"
                      class="date-picker"
                      :input-style="styleDatetime"
                      v-model="field.value"
                      placeholder="DD/MM/YYYY"
                      format="dd/MM/yyyy"
                      value-zone="Asia/Bangkok"
                      :ref="`${field.id}-date`"
                    >
                    </datetime>

                    <div
                      :class="'icon-primary text-right'"
                      @click="$refs[`${field.id}-date`][0].isOpen = true"
                    >
                      <font-awesome-icon
                        icon="calendar-alt"
                        :class="'pointer color-primary'"
                        color="#B41BB4"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id === 8"
            >
              <UploadFile
                v-model="field.value"
                :value="field.value"
                :textFloat="field.name"
                :placeholder="field.name"
                format="image"
                name="thumbnail"
                className="mb-0"
                accept="image/jpeg, image/png"
                text="*Please upload only file .png, .jpg size 1:1  and less than 10 MB"
                v-on:onFileChange="($event) => onImageChange($event, index)"
                :fileName="field.value"
              />
              <div @click="showImage(field.value)" v-if="field.value">
                <PreviewBox
                  v-on:delete="onDeleteImage(index)"
                  :disabled="false"
                  :showPreviewType="0"
                  :showPreview="field.value"
                  :isLoadingImage="false"
                  :ratioType="1"
                />
              </div>
            </b-col>
            <b-col
              :key="'field-' + field.id + index"
              v-else-if="field.field_type_id === 12"
            >
              <InputTextArea
                :textFloat="field.name"
                v-model="field.value"
                type="text"
                :placeholder="field.name"
                className="mb-0"
                rows="3"
              />
            </b-col>
            <div class="break-normal" :key="'break-normal' + field.id"></div>
          </template>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import Multiselect from "vue-multiselect";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  components: { OtherLoading, Multiselect, PreviewBox },
  props: {
    form: {
      required: false,
    },
  },
  data() {
    return {
      isLoading: true,
      selected: [],
      styleDatetime: {
        width: "100%",
        border: "none",
      },
    };
  },

  validations: {},
  watch: {},
  async created() {
    this.getField();
  },

  methods: {
    async getField() {
      this.isLoading = false;
    },
    onImageChange(img, index) {
      this.$bus.$emit("showLoading");
      var reader = new FileReader();
      reader.readAsDataURL(img);
      reader.onload = async () => {
        const images = await this.saveImagetoDb(reader.result);
        this.isLoadingImage = false;
        this.isDisable = false;
        this.form.fields[index].value = images;

        this.$forceUpdate();
        this.$bus.$emit("hideLoading");
      };
    },
    saveImagetoDb: async function (img) {
      var form = {
        Base64String: img,
      };
      await this.$store.dispatch("ActionUploadImage", form);
      var data = await this.$store.state.moduleConnectAPI.stateUploadImage;
      if (data.result == 1) {
        return data.detail;
      }
    },
    onDeleteImage(index) {
      this.form.fields[index].value = null;
      this.$forceUpdate();
    },
  },
};
</script>

<style lang="scss" scoped></style>
